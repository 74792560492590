


export class BrowserAppEvaluationToolInfo {

  public version = '2024.6.18.1';

  constructor() {
  }

}
